/* =================== HEADER CSS =================== */
.__header__ {
  //   margin-left: 250px;
  transition: all 0.5s ease;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    background-image: linear-gradient(to right, lightblue, #666262);
  }

  .container {
    width: auto;
    height: 72px;
    background-image: linear-gradient(to bottom, lightseagreen, lightblue);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    padding: 1rem 2rem;
    box-shadow: 0 0 0.5rem saddlebrown;
    max-width: unset;
  }

  .container__logo {
    color: #000;
    font-size: 1.5rem;
    letter-spacing: 0.5rem;
    font-weight: 900;
    text-shadow: 0 0 1px #000;
    font-variant: small-caps;
    animation: move 2s ease-in-out 0s 1;
  }

  @keyframes move {
    from {
      margin-top: 100%;
    }

    to {
      margin-bottom: 0;
    }
  }

  .container__menu {
    font-size: 1.2rem;
    animation: fold 1s linear 0s 1;
  }

  .container__menu a {
    text-decoration: none;
    color: #000;
    padding: 0.5rem 1rem;
    border: 1px solid #000;
    border-radius: 8px;
    text-shadow: 0 0 1px #000;
    animation: fold 1s linear 0s 1;
  }

  .menubtn {
    display: none;
    color: #000;
    padding: 0.5rem;
    border: 1px solid #000;
    border-radius: 8px;
    font-size: 1.6rem;
  }

  .closebtn {
    display: none;
    color: #000;
    padding: 0.5rem;
    border: 1px solid #000;
    border-radius: 8px;
    font-size: 1.6rem;
  }

  .container__menu a::selection {
    color: red;
  }

  @keyframes fold {
    from {
      transform: skew(10deg);
      color: green;
      background-color: #000;
    }

    to {
      transform: skew(160deg);
    }
  }

  @media (max-width: 618px) {
    .container__menu {
      display: none;
    }

    .menubtn {
      display: block;
    }
  }
  @media (min-width: 620px) {
    .menubtn {
      display: none;
    }
  }
}
